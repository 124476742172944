// <Add new locale here>
// We define our locales in next.config.js which is the locale shown in the url.
// This might be shorter for display purposes than the matching locale in storyblok.

import { CurrencyCode, Price } from "types/attheminute";

// <Add new locale here>
export const SUPPORTED_CURRENCIES: { [key: string]: CurrencyCode} = {
  NZD: "NZD",
  USD: "USD",
  GBP: "GBP",
  AUD: "AUD",
  CAD: "CAD",
  ZAR: "ZAR",
};

export const SUPPORTED_LOCALES: { [key: string]: string} = {
  "us": "United States",
  "gb": "United Kingdom",
  "ca": "Canada",
  "nz": "New Zealand",
  "au": "Australia",
  "za": "South Africa",
};

const DEFAULT_CURRENCY = SUPPORTED_CURRENCIES.NZD;

// <Add new locale here>
// This will do that mapping. E.g. us -> en-us.
export const storyblokLocaleFromNextJSLocale = (locale?: string) => {
  const basicMap: { [key: string]: string } = {
    default: "Default",
    nz: "Default",
    gb: "en-gb",
    au: "en-au",
    us: "en-us",
    ca: "en-ca",
    za: "en-za",
  };

  if (locale && basicMap.hasOwnProperty(locale)) {
    return basicMap[locale];
  }
  return "Default";
};

// <Add new locale here>
// This one is used for Intl library. NOT for storyblok.
export const i8nLocaleFromCurrency = (locale?: string) => {
  const basicMap: { [key: string]: string } = {
    [SUPPORTED_CURRENCIES.NZD]: "en-NZ",
    [SUPPORTED_CURRENCIES.GBP]: "en-GB",
    [SUPPORTED_CURRENCIES.AUD]: "en-AU",
    [SUPPORTED_CURRENCIES.USD]: "en-US",
    [SUPPORTED_CURRENCIES.CAD]: "en-CA",
    [SUPPORTED_CURRENCIES.ZAR]: "en-ZA",
  };

  if (locale && basicMap.hasOwnProperty(locale)) {
    return basicMap[locale];
  }
  return "en-NZ";
};

// <Add new locale here>
export const currencyFromNextJSLocale = (locale?: string): CurrencyCode => {
  const basicMap: { [key: string]: CurrencyCode } = {
    default: DEFAULT_CURRENCY,
    nz: SUPPORTED_CURRENCIES.NZD,
    gb: SUPPORTED_CURRENCIES.GBP,
    au: SUPPORTED_CURRENCIES.AUD,
    us: SUPPORTED_CURRENCIES.USD,
    ca: SUPPORTED_CURRENCIES.CAD,
    za: SUPPORTED_CURRENCIES.ZAR,
  };
  if (locale && basicMap.hasOwnProperty(locale)) {
    return basicMap[locale];
  }
  return DEFAULT_CURRENCY;
};

// E.g: 1NZD = 0.7USD

// Using a manual fx rate fixes prices for customers but means we will get more or less money based on
// fx fluctuations.
// E.g:
// If lower: 1.00 NZD = 0.5 USD then we will get MORE back in NZD using a fixed 0.65
// If higher: 1.00 NZD = 1.00 USD then we will get LESS back in NZD using a fixed 0.65. (1 * 0.65: 0.65usd. But converted back to nzd would be 0.65nzd)

// Currently based on average exchange rate over last 5 years:
// https://www.ofx.com/en-nz/forex-news/historical-exchange-rates/yearly-average-rates/
// <Add new locale here>
export const manualCurrencyFXRates = {
  NZD: 1,
  USD: 0.65,
  GBP: 0.5,
  AUD: 0.9,
  CAD: 0.85,
  ZAR: 10,
};

export const convertCurrency = (price: Price, currency: CurrencyCode) => {
  if (
    price.currency != SUPPORTED_CURRENCIES.NZD ||
    !manualCurrencyFXRates.hasOwnProperty(currency) ||
    price.amountInSmallestUnit === -1
  ) {
    return price;
  }

  const rate = manualCurrencyFXRates[currency];
  return {
    amountInSmallestUnit: price.amountInSmallestUnit * rate,
    currency,
  };
};

export const pathsForEachLocale = (
  paths: {
    params: {
      id?: string;
      slug?: string[];
    };
  }[],
  locales: string[] | undefined
) => {
  let pathsWithLocales;
  if (locales) {
    pathsWithLocales = paths
      .map((path) => {
        return locales?.map((locale) => ({
          ...path,
          locale,
        }));
      })
      .flat();
  } else {
    pathsWithLocales = paths;
  }
  return pathsWithLocales;
};
