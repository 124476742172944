import merge from "lodash.merge";

import prod from "./prod";
import local from "./local";

function getConfig() {
  // Any default config here
  const defaultConfig = {
    vapidPublicKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY,
  };

  // production or development
  const env = process.env.NODE_ENV;

  if (env === "production") {
    return merge(defaultConfig, prod);
  }
  return merge(defaultConfig, local);
}

export default getConfig;
