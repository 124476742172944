import NextImage from "next/legacy/image";
import Link from "next/link";
import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";
import { lightShadow } from "styles/tokens";
import { TextH4, TextSmallP, fontFamilyLabel } from "styles/typography";

const cardStyles = `
  text-decoration: none;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardLink = styled(Link)`
  ${cardStyles}
`;

export const Card = styled.a`
  ${cardStyles}
`;

export const Top = styled.figure`
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin: 0px;
  overflow: hidden;
  border-radius: 4px;

  box-shadow: ${lightShadow};
`;

export const Bottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;

  padding-top: ${spacing.md};
  padding-left: ${spacing.sm};
  padding-right: ${spacing.sm};
`;

export const Image = styled(NextImage)`
  object-fit: cover;
`;

export const Heading = styled(TextH4)`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
`;

export const Footer = styled.div<{ $isFree?: boolean }>`
  margin-bottom: 0;
  font-size: 16px;
  font-family: ${fontFamilyLabel};
  text-align: right;
  padding-left: ${spacing.sm};
  padding-right: ${spacing.sm};
  padding-bottom: ${spacing.sm};

  padding-top: ${spacing.xs};

  ${(props) =>
    props.$isFree
      ? `color: var(--color-cornflowerBlue);`
      : `color: var(--color-blueZodiac);`}
`;

export const SeriesContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
