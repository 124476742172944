import { ThemeContext } from "components/hocs/ThemeProvider";
import NextLink from "next/link";
import React from "react";
import { ImageOrFile, Price } from "types/attheminute";
import { formatPrice } from "utils/storeHelpers";
import { storyblokLoader } from "utils/storyblok";
import * as Styled from "./TeachingResourceCard.styled";

interface TeachingResourceCardProps {
  previewImage?: ImageOrFile;
  heading?: string;
  fullSlug: string;
  price?: Price;
  isAvailableOnTPT?: boolean;
}

export default function TeachingResourceCard(props: TeachingResourceCardProps) {
  const { previewImage, heading, fullSlug, price, isAvailableOnTPT } =
    props;

  let priceText = "";
  const isFree = price && price.amountInSmallestUnit === 0;

  if (isFree) {
    priceText = "Free";
  } else if (price !== undefined) {
    priceText = `${formatPrice(price)}`;
  } else if (isAvailableOnTPT) {
    priceText = "Available on TPT";
  } else {
    priceText = "Unavailable";
  }

  return (
    <NextLink href={`/${fullSlug}`} passHref legacyBehavior>
      <Styled.Card>
        <Styled.Top>
          {previewImage?.url ? (
            <Styled.Image
              src={previewImage.url}
              alt={previewImage.alt}
              layout="fill"
              loader={storyblokLoader}
            />
          ) : (
            <Styled.Image
              src={"/images/placeholder.png"}
              alt={""}
              layout="fill"
              loader={storyblokLoader}
            />
          )}
        </Styled.Top>
        <Styled.Bottom>
          {heading && <Styled.Heading>{heading}</Styled.Heading>}
        </Styled.Bottom>
        {priceText && (
          <Styled.Footer $isFree={isFree}>{priceText}</Styled.Footer>
        )}
      </Styled.Card>
    </NextLink>
  );
}
