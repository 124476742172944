import axios from "axios";
import { Cart, CurrencyCode, Price } from "types/attheminute";
import qs from "query-string";
import getConfig from "../config";

const config = getConfig();

const apiHostname = config.api.hostname;
const CREATE_CHECKOUT_SESSION_PATH = "atm-api/v1/checkout/create-session";
const PURCHASE_BASE_URL = "atm-api/v1/purchase";
const PRODUCT_PRICE = "atm-api/v1/product/price";

export async function createCheckoutSession(
  cart: Cart,
  currency: CurrencyCode
) {
  const body = {
    lineItems: cart.map((cartItem) => ({
      id: cartItem.id,
      quantity: cartItem.quantity,
    })),
    currency,
  };

  const result = await axios.post(
    `${apiHostname}/${CREATE_CHECKOUT_SESSION_PATH}`,
    body
  );
  return result.data.redirectUrl;
}

export async function getPurchaseByOrderId(orderId: string) {
  const result = await axios.get(
    `${apiHostname}/${PURCHASE_BASE_URL}/${orderId}`
  );
  return result;
}

export async function getProductPrice(
  productIds: string | string[],
  currency: CurrencyCode
): Promise<{
  productId: string;
  price: Price
}[]> {
  if (!currency || !productIds) {
    throw new Error("Cannot get product price without ID or currency");
  }

  const params = qs.stringify({
    currency,
    productIds,
  });

  const result = await axios.get(`${apiHostname}/${PRODUCT_PRICE}?${params}`);
  return result.data;
}

export async function downloadProductByOrderId(
  orderId: string,
  productId: string
) {
  const url = `${apiHostname}/${PURCHASE_BASE_URL}/${orderId}/${productId}/download`;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "attheminute-resource");
  document.body.appendChild(link);
  link.click();
}

export async function downloadFreeProduct(productId: string) {
  const url = `${apiHostname}/${PURCHASE_BASE_URL}/free-download/${productId}`;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "attheminute-resource");
  document.body.appendChild(link);
  link.click();
}
