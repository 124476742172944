import * as React from "react";
import Link from "next/link";
import styled from "styled-components";
import { sFont, fontFamilyLabel } from "styles/typography";
import spacing from "styles/spacing";

export const breadcrumbFontStyles = `
  font-family: ${fontFamilyLabel};
  color: var(--color-label);
  font-size: ${sFont};
  line-height: 1.6;
  text-decoration: none;
`;

const BreadcrumbLink = styled.a`
  ${breadcrumbFontStyles}

  :hover {
    color: var(--color-coolAccent);
  }
`;

const SeparatorWrapper = styled.span`
  ${breadcrumbFontStyles}
  margin: 0 ${spacing.xs};
`;

const Text = styled.span`
  ${breadcrumbFontStyles}
`;

export interface Breadcrumb {
  content: React.ReactNode;
  link?: string;
}

interface BreadcrumbsProps {
  breadcrumbs?: Breadcrumb[];
  className?: string;
}

const Separator = <SeparatorWrapper>/</SeparatorWrapper>;

export default function Breadcrumbs(props: BreadcrumbsProps) {
  const { breadcrumbs, className } = props;

  return (
    <div className={className}>
      {breadcrumbs
        ?.map((breadcrumb, index) => {
          if (breadcrumb.link) {
            return (
              <Link key={breadcrumb.link} href={breadcrumb.link} passHref legacyBehavior>
                <BreadcrumbLink>{breadcrumb.content}</BreadcrumbLink>
              </Link>
            );
          }

          return <Text key={index}>{breadcrumb.content}</Text>;
        })
        .reduce((acc, element) =>
          acc === null ? (
            element
          ) : (
            <>
              {acc}
              {Separator}
              {element}
            </>
          )
        )}
    </div>
  );
}
