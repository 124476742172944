import { Cart, CurrencyCode, Price } from "types/attheminute";
import { i8nLocaleFromCurrency, SUPPORTED_CURRENCIES } from "./localisation";

const CART_LOCAL_STORAGE_NAME = "atm_cart";

export function saveLocalData(cart: Cart) {
  localStorage.setItem(CART_LOCAL_STORAGE_NAME, JSON.stringify(cart));
}

export function getLocalCart(): Cart {
  const localData = localStorage.getItem(CART_LOCAL_STORAGE_NAME);
  if (!localData) return [];
  return JSON.parse(localData);
}

export function getCartSubTotal(cart: Cart, currency: CurrencyCode) {
  const amount = cart.reduce((total, currentItem) => {
    return (
      total +
      currentItem.quantity *
        (currentItem.price?.amountInSmallestUnit
          ? currentItem.price.amountInSmallestUnit
          : 0)
    );
  }, 0);

  return {
    amountInSmallestUnit: amount,
    currency
  }
}

export function numberOfCartItems(cart: Cart) {
  return cart.reduce((numItems, currentItem) => {
    return numItems + currentItem.quantity;
  }, 0);
}

const isWholeNumber = (num: number) => num % 1 == 0;

const defaultOptions = {
  alwaysShowDecimal: false,
  decimalPlaces: 2,
};

export function formatPrice(
  price: Price,
  options?: { alwaysShowDecimal?: boolean; decimalPlaces?: number }
) {
  const i8nLocale = i8nLocaleFromCurrency(price.currency);
  return new Intl.NumberFormat(i8nLocale, {
    style: "currency",
    currency: price.currency,
  }).format(price.amountInSmallestUnit / 100); // NOTE THIS COULD BE A PROBLEM FOR YEN IF WE EVER NEED IT.

  // const { decimalPlaces, alwaysShowDecimal } = {
  //   ...defaultOptions,
  //   ...options,
  // };
  // const dollars = price / 100;

  // let amount;
  // if (isWholeNumber(dollars) && !alwaysShowDecimal) {
  //   amount = dollars;
  // } else {
  //   amount = dollars.toFixed(decimalPlaces);
  // }
  // return `$${amount}`;
}
