import { Cart, CartItem, Price } from "types/attheminute";
import * as React from "react";
import { getLocalCart, saveLocalData } from "utils/storeHelpers";
import cloneDeep from "lodash.clonedeep";
import { useRouter } from "next/router";
import { currencyFromNextJSLocale } from "utils/localisation";
import { getProductPrice } from "api/marketplace";

const noop = () => {};

type AddToCartFn = (cartItem: CartItem) => void;
type ClearCartFn = () => void;
type UpdateCartQuantityFn = (id: string, quantity: number) => void;

const CartContext = React.createContext<Cart>([]);
const CartMetaContext = React.createContext<{
  isOpen: boolean;
  isFetchingPrices: boolean;
  toggleCartOpen: () => void;
}>({
  isOpen: false,
  isFetchingPrices: false,
  toggleCartOpen: () => {},
});
const AddToCartContext = React.createContext<AddToCartFn>(noop);
const ClearCartContext = React.createContext<ClearCartFn>(noop);
const UpdateCartQuantityContext =
  React.createContext<UpdateCartQuantityFn>(noop);

export function useCartMetaContext() {
  return React.useContext(CartMetaContext);
}
export function useCartContext() {
  return React.useContext(CartContext);
}

export function useAddToCartContext() {
  return React.useContext(AddToCartContext);
}

export function useClearCartContext() {
  return React.useContext(ClearCartContext);
}

export function useUpdateCartQuantityContext() {
  return React.useContext(UpdateCartQuantityContext);
}

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [cart, setCart] = React.useState<Cart>([]);
  const [isOpen, setCartOpen] = React.useState(false);
  const [isFetchingPrices, setFetchingPrices] = React.useState(false);

  const { locale } = useRouter();
  const currency = currencyFromNextJSLocale(locale);

  const refetchCartItemPrices = async (productIds: string[]) => {
    setFetchingPrices(true);
    const productPriceArray = await getProductPrice(productIds, currency);
    const newPriceMap: { [key: string]: Price } = {};
    productPriceArray.forEach((productPrice) => {
      newPriceMap[productPrice.productId] = productPrice.price;
    });

    const clonedCart = cloneDeep(cart);
    const newCart = clonedCart.map(item => {
      if (newPriceMap[item.id]) {
        return {
          ...item,
          price: newPriceMap[item.id],
        }
      }
      return item;
    });

    setCart(newCart);
    setFetchingPrices(false);
  }

  React.useEffect(() => {
    if (!isFetchingPrices) {
      let productIdsToRefetch: string[] = [];
      cart.forEach((cartItem) => {
        if (cartItem.price.currency != currency) {
          productIdsToRefetch.push(cartItem.id);
        }
      });
  
      if (productIdsToRefetch.length > 0) {
        refetchCartItemPrices(productIdsToRefetch);
      }
    } 
  }, [currency, cart, isFetchingPrices]);

  const toggleCartOpen = () => {
    setCartOpen(!isOpen);
  };

  React.useEffect(() => {
    setCart(getLocalCart());
  }, []);

  React.useEffect(() => {
    // do this to make sure multiple tabs are always in sync
    const onReceiveMessage = (e: StorageEvent) => {
      setCart(getLocalCart());
    };

    window.addEventListener("storage", onReceiveMessage);
    return () => {
      window.removeEventListener("storage", onReceiveMessage);
    };
  }, []);

  const addToCart: AddToCartFn = (newItem: CartItem) => {
    if (newItem.price === undefined) {
      return;
    }

    // empty cart
    if (cart.length === 0) {
      const newCart = [newItem];
      setCart(newCart);
      saveLocalData(newCart);
      return;
    }

    const newCart = cloneDeep(cart);
    let itemAdded = false;
    // loop through all cart items to check if variant
    // already exists and update quantity
    newCart.map((item) => {
      if (item.id === newItem.id) {
        // The following is commented out so that you can only add ONE quantity to your cart
        // item.quantity += newItem.quantity;
        item.quantity = 1;
        itemAdded = true;
      }
    });

    if (!itemAdded) {
      newCart.push(newItem);
    }

    setCart(newCart);
    saveLocalData(newCart);
  };

  const clearCart = () => {
    setCart([]);
    saveLocalData([]);
  };

  const updateCartItemQuantity: UpdateCartQuantityFn = (
    id: string,
    quantity: number
  ) => {
    let newCart = cloneDeep(cart);
    newCart.forEach((item) => {
      if (item.id === id) {
        item.quantity = quantity;
      }
    });

    // take out zeroes items
    newCart = newCart.filter((i) => i.quantity !== 0);

    setCart(newCart);
    saveLocalData(newCart);
  };

  return (
    <CartContext.Provider value={cart}>
      <ClearCartContext.Provider value={clearCart}>
        <CartMetaContext.Provider
          value={{
            isOpen,
            toggleCartOpen,
            isFetchingPrices,
          }}
        >
          <AddToCartContext.Provider value={addToCart}>
            <UpdateCartQuantityContext.Provider value={updateCartItemQuantity}>
              {children}
            </UpdateCartQuantityContext.Provider>
          </AddToCartContext.Provider>
        </CartMetaContext.Provider>
      </ClearCartContext.Provider>
    </CartContext.Provider>
  );
}

export function CartProviderSingletonLayout(props: {
  children: React.ReactNode;
}) {
  return <CartProvider>{props.children}</CartProvider>;
}
