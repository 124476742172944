export default function Underline(props: {
  colour?: string;
  className?: string;
}) {
  const { colour, className } = props;

  return (
    <svg
      className={className}
      width="261"
      height="12"
      viewBox="0 0 261 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.500036 8.13317C76 3 125.76 1.63047 260.133 8.13321"
        stroke={colour || "#BFAF8F"}
        strokeWidth="6"
      />
    </svg>
  );
}
